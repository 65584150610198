import React, { useState, useEffect } from "react";
import Link from "next/link";
import useWindowSize from "@/hooks/useWindowSize";
import Image from "next/legacy/image";
import { Navigation, Thumbs, FreeMode, Autoplay } from "swiper";
import * as Text from "@/components/text";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";

import Button from "@/components/actions/Button";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/autoplay";
import breakpoints from "@/constants/breakpoinst";

const image =
  "https://images.unsplash.com/photo-1592561199818-6b69d3d1d6e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3388&q=80";

export default function HomeSlider({ slides = [] }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const windowSize = useWindowSize();

  const handleOnTouchEnd = (swiperItem, e) => {
    swiperItem.allowClick = true;
  };

  return (
    <div className="">
      <div className="relative h-[80vh] w-full min-w-full md:h-[900px]">
        <div className="w-full h-full">
          <Swiper
            onTouchEnd={handleOnTouchEnd}
            noSwiping={windowSize.width > breakpoints.lg}
            noSwipingClass="swiper-no-swiping"
            className="relative w-full h-full hero-swiper swiper-no-swiping"
            spaceBetween={10}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            modules={[Autoplay, Thumbs, Navigation]}
          >
            <>
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  {slide.big?.fileType === 'video' ? (
                    <video
                      autoPlay
                      muted
                      loop
                      id="myVideo"
                      poster={slide?.big?.image || image}
                      className="absolute top-0 object-cover w-full h-full "
                    >
                      <source
                        src={slide?.big?.image}
                        type={slide?.big?.mime}
                      />

                    </video>
                  ) : (
                    <img
                      className="object-cover w-full h-full"
                      src={slide?.big?.image || image}
                      alt=""
                    />
                  )}

                  <SliderBigContent
                    head={slide.big.head}
                    title={slide.big.title}
                    subtitle={slide.big.subtitle}
                    description={slide.big.description}
                    link={slide.big.link}
                  />
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        </div>
        <div className="amb-container absolute left-0 right-0 z-10 sm:bottom-0  lg:bottom-[-20px] xl:px-0 ">
          <Swiper
            onTouchEnd={handleOnTouchEnd}
            observer={true}
            className="lowerSwiper"
            onSwiper={setThumbsSwiper}
            slidesPerView={slides.length}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            <>
              {slides.map((slide, index) => (
                <SwiperSlide
                  key={index}
                  className={
                    windowSize.width < breakpoints.lg ? "pr-2" : "pr-8"
                  }
                >
                  <SliderThumb
                    head={slide.small.head}
                    title={slide.small.title}
                    subtitle={slide.small.subtitle}
                    thumbImage={slide.small.thumbImage}
                    link={slide.big.link}
                  />
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

function SliderBigContent({
  head = "",
  title = "",
  subtitle = "",
  description = "",
  buttonLabel = "Leer más",
  link = "",
}) {
  const windowSize = useWindowSize();
  return (
    <div className="absolute top-0 z-20 w-full h-full swiper-no-swiping content xl:mx-auto">
      <div className="flex h-full text-white amb-container md:h-3/4 md:items-center md:align-middle xl:px-0">
        <div className="self-end space-y-5 mb-14 md:w-2/3">
          <Text.Title size={windowSize.width > breakpoints.md ? "xl" : "lg"}>
            {head}
          </Text.Title>
          {windowSize.width > breakpoints.md ? (
            <Text.Display size="sm" as="h2">
              {title}
            </Text.Display>
          ) : null}
          {windowSize.width <= breakpoints.md ? (
            <Text.Headline as="h2">{title}</Text.Headline>
          ) : null}
          <Text.Title
            fontWeight="normal"
            size={windowSize.width < breakpoints.lg ? "md" : "lg"}
          >
            <div
              className="hidden pt-5 md:block"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </Text.Title>

          <div className="flex w-full">
            <Link href={link} className="w-full md:w-auto">
              <Button
                trailingIcon={<ArrowNarrowRightIcon></ArrowNarrowRightIcon>}
                label={buttonLabel}
                size={windowSize.width < 400 ? "sm" : "md"}
                color="secondary-dark"
                className="mt-10 w-[300px] md:w-auto md:w-full"
              ></Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function SliderThumb({
  title = "",
  subtitle = "",
  head = "",
  thumbImage = "",
  link = ""
}) {
  const windowSize = useWindowSize();
  const css = classNames(
    " md:grid grid-cols-3 gap-3 border-t-2 border-top-slide cursor-pointer"
  );
  return (
    <>
      {windowSize.width > breakpoints.lg ? (
        <div className={`${css} lg:mb-16 lg:flex lg:flex-row lg:flex-wrap `}>
          <div className="flex-[1_100%] lg:mb-3">
            <Text.Overline
              size={windowSize.width < breakpoints.lg ? "sm" : "md"}
              className="pt-1 swiper-title"
              as="h3"
            >
              {head}
            </Text.Overline>
          </div>
          <div className="space-y-3 md:block lg:flex-[1_40%]">
            <Text.Label
              className="accent"
              size={windowSize.width < breakpoints.lg ? "sm" : "lg"}
              as="p"
            >
              <Link href={link} className="hover:underline">
                {title}
              </Link>
            </Text.Label>
            <Text.Title
              fontWeight="normal"
              className="line-clamp-3 "
              size={windowSize.width < 900 ? "md" : "lg"}
            >
              <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
            </Text.Title>
          </div>
          <div className="lg:block">
            <div className="thumb-image mt-0 aspect-square w-[74px] pt-1">
              <Image
                className="object-cover "
                alt="slideshow-preview"
                width={200}
                height={200}
                src={
                  thumbImage ||
                  "/next_assets/img/brand-assets/ambulante-bg-black.svg"
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={css}>
          <div className="hidden col-span-2 space-y-3 md:block">
            <Text.Overline
              size={windowSize.width < breakpoints.lg ? "sm" : "md"}
              className="pt-3 swiper-title"
              as="h3"
            >
              {head}
            </Text.Overline>
            <Text.Label
              className="accent"
              size={windowSize.width < breakpoints.lg ? "sm" : "lg"}
              as="p"
            >
              {title}
            </Text.Label>
            <Text.Title
              fontWeight="normal"
              className="line-clamp-3 "
              size={windowSize.width < 900 ? "md" : "lg"}
            >
              <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>
            </Text.Title>
          </div>
          <div className="hidden lg:block">
            <div className="pt-1 mt-3 thumb-image aspect-square">
              <Image
                className="object-cover "
                alt="slideshow-preview"
                width={200}
                height={200}
                src={
                  thumbImage ||
                  "/next_assets/img/brand-assets/ambulante-bg-black.svg"
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
